import { FC } from 'react';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';

import { ArticleTypes } from 'src/models/articlePage/types';

import BlogArticle from 'src/pages/Article/components/Content/BlogArticle';
import LandingArticle from 'src/pages/Article/components/Content/LandingArticle';

const Content: FC = () => {
    const { type, id } = useSelectorNonNullable((state) => state.articlePage);

    return type === ArticleTypes.Promo ? <LandingArticle /> : <BlogArticle articleId={id} />;
};

export default Content;
