import { memo } from 'react';
import classNames from 'classnames';

import Column from 'bloko/blocks/column';
import { H2Section } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ArticleListItemMedium from 'src/components/ArticleItem/ArticleListItemMedium';
import translation from 'src/components/translation';
import { ReadMoreArticle } from 'src/models/articlePage';

import styles from './index.less';

const TrlKeys = {
    title: 'article.readMore',
};

interface ReadMoreProps {
    readMoreArticles: ReadMoreArticle[];
}

const ReadMore: TranslatedComponent<ReadMoreProps> = ({ trls, readMoreArticles }) => {
    return (
        <Column xs={'4'} s={'8'} m={'8'} l={'8'}>
            <div className={styles.readMore}>
                <VSpacing xs={5} base={11} />
                <H2Section>{trls[TrlKeys.title]}</H2Section>
                <VSpacing xs={6} base={5} />
                <div className={styles.list}>
                    {readMoreArticles.map((item) => (
                        <div key={item.code} className={classNames(styles.itemContainer)}>
                            <Column xs="4" s="4" m="4" l="4">
                                <ArticleListItemMedium item={item} />
                            </Column>
                        </div>
                    ))}
                </div>
            </div>
        </Column>
    );
};

export default translation(memo(ReadMore));
