import Analytics from '@hh.ru/analytics-js';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';
import VacancySummary from 'src/pages/Article/components/VacancySummary';

const TrlKeys = {
    header: 'index.headers.recommended',
};

type Props = {
    collection: VacancySearchItem[];
};

const SuitableVacancies: TranslatedComponent<Props> = ({ collection, trls }) => {
    return (
        <div>
            <H3>{trls[TrlKeys.header]}</H3>
            <VSpacing base={4} />
            <div className="recommended-vacancies">
                {collection.map((vacancy) => (
                    <div
                        key={vacancy.vacancyId}
                        className="recommended-vacancies__item recommended-vacancies__item_full"
                    >
                        <VacancySummary
                            vacancy={vacancy}
                            vacancyLinkQueryParams={{ hhtmFromLabel: 'articles_recommended' }}
                            onClick={() => Analytics.sendHHEventButtonClick('recommended_vacancy_from_article')}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default translation(SuitableVacancies);
